import React from "react";

const FlexibilityDescription = () => {
  return (
    <section
      className="relative w-full py-16 lg:py-24 bg-cover bg-fixed"
      style={{
        backgroundImage: "url('/Video_Background.jpg')",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      {/* Overlay */}
      <div
        className="absolute inset-0"
        style={{
          backgroundColor: "rgba(255, 250, 248, 0.38)", // Soft overlay
        }}
      ></div>

      <div className="container relative z-10 mx-auto px-6 flex flex-col lg:flex-row-reverse items-center justify-between">
        {/* Image Section */}
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end order-1 lg:order-1 mb-8 lg:mb-0">
          <div className="relative -ml-0 lg:-ml-20">
            <img
              src="/Flex_Description.webp"
              alt="Flexibility Pose"
              className="w-[400px] lg:w-[500px] h-auto rounded-lg object-cover"
            />
          </div>
        </div>

        {/* Text Section */}
        <div className="w-full lg:w-1/2 order-2 lg:order-1 text-left">
          <h2
            className="text-center text-[#1d1d1d] font-notoserif font-light leading-tight mt-0 mb-2 text-4xl md:text-4xl lg:text-4xl"
            style={{
              WebkitFontSmoothing: "antialiased",
              textRendering: "optimizeLegibility",
            }}
          >
            The best course to help dancers gain flexibility!
          </h2>
          <div className="flex justify-center lg:justify-start my-4 mb-12">
            <img
              src="/divider.png"
              alt="Divider"
              className="block mx-auto w-[176px] h-auto"
            />
          </div>

          {/* List with descriptions */}
          <ul className="list-none pl-0">
            <li className="relative flex items-start mb-6">
              <i className="fas fa-check text-[#e1a291] text-lg mr-3 absolute"></i>
              <div className="pl-8">
                <strong className="text-lg md:text-lg inline-block mb-2">
                  Finally, Stop Fighting your Body
                </strong>
                <br />
                Learn how to bio-hack your brain to override the things slowing
                your flexibility progress (Did you know flexibility is actually
                created in your mind?!)
              </div>
            </li>
            <li className="relative flex items-start mb-6">
              <i className="fas fa-check text-[#e1a291] text-lg mr-3 absolute"></i>
              <div className="pl-8">
                <strong className="text-lg md:text-lg inline-block mb-2">
                  Learn Proven Formulas to Level up your Dancing with FUNCTIONAL
                  Flexibility
                </strong>
                <br />
                Go from Tight & Limited to Bendy & Fluid (Injury Free!) Make
                your flexibility work for you & start using your full mobility
                effortlessly.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FlexibilityDescription;
