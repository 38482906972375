import React from "react";

const Welcome = () => {
  return (
    <section
      className="relative py-8 lg:py-24 lg:bg-fixed welcome-section"
      style={{
        backgroundImage: "url('/Welcome_Background.jpg')", // Background image for desktop
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent repeating
        backgroundSize: "cover", // Cover the entire section with the image
        backgroundAttachment: "fixed", // Fixed background for desktop view
      }}
    >

      {/* Mobile Image Section (Header/Hero) */}
      <div className="block lg:hidden mb-[-40px] mt-[-30px]">
        <img
          src="/Welcome_Mobile.jpg"
          alt="Welcome Mobile Header"
          className="w-full object-cover"
          style={{
            margin: "0", // Ensure no extra margin
          }}
        />
      </div>

      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center">
          {/* Text Section with AOS Animation and Padding */}
          <div
            className="lg:w-[60%] w-full p-6 lg:p-16 text-center relative bg-light-background"
            data-aos="fade-up" // AOS effect
            data-aos-duration="1000" // Duration of the effect
            style={{
              border: "1px solid #d88f96", // Border matching the reference
              borderRadius: "4px", // Rounded corners
              backgroundColor: "#eddbcd", // Background color inside the box
              paddingTop: "70px", // Top padding
              paddingBottom: "75px", // Bottom padding
              maxWidth: "800px", // Make the container wider
              margin: "0 auto", // Center the box horizontally
            }}
          >
            {/* Welcome Text */}
            <p
              className="text-center"
              style={{
                letterSpacing: "0.2em",
                fontSize: "18px", // Smaller font size for mobile
                color: "#9d646b", // Color for "WELCOME TO THE"
              }}
            >
              WELCOME TO THE
            </p>

            {/* Header: Yoga for Dancers Family */}
            <h4
              className="font-bird-and-thorn text-5xl lg:text-7xl" // Adjust font size for mobile
              style={{
                marginTop: "15px",
                textAlign: "center", // Center text
                fontWeight: "400", // Light weight font
                color: "#1d1d1d", // Header text color
                lineHeight: "1.2",
                whiteSpace: "nowrap", // Prevent line breaks on desktop
              }}
            >
              Yoga for Dancers Family!
            </h4>

            {/* Subtext */}
            <p
              className="text-base text-[#333333] leading-relaxed font-raleway mt-4"
              style={{
                textAlign: "center",
                fontSize: "16px", // Slightly smaller font size for mobile
                fontWeight: "400", // Paragraph font weight
                lineHeight: "1.5", // Adjusted line height for readability
                letterSpacing: "normal", // Ensure no additional letter spacing
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Improved text rendering
              }}
            >
              Would you believe me if I told you doctors once said I would never
              dance again?
            </p>

            <p
              className="text-base text-[#333333] leading-relaxed font-raleway mb-8"
              style={{
                marginTop: "10px",
                textAlign: "center",
                fontSize: "16px", // Slightly smaller font size for mobile
                fontWeight: "400", // Paragraph font weight
                lineHeight: "1.5", // Adjusted line height for readability
                letterSpacing: "normal", // Ensure no additional letter spacing
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Improved text rendering
              }}
            >
              My journey from pre-professional dancer to Dance Wellness mentor
              has been anything but smooth...but it's the driving force behind
              the mission of Yoga for Dancers. Click below to learn my whole
              back story.
            </p>

            {/* Updated Button */}
            <a
              href="https://www.youtube.com/watch?v=FscnvHca9Ug&t=193s"
              target="_blank"
              rel="noopener"
              className="inline-block bg-gradient-to-r from-yellow-400 to-yellow-600 text-white text-lg py-3 px-8 shadow-lg transition duration-300 ease-in-out hover:shadow-xl rounded-xl font-light mt-6"
            >
              STORY OF A DANCE SCHOOL DROPOUT
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
