import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Gallery = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
  }, []);

  return (
    <section>
      <div className="w-full mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4">
          {/* Image 1 */}
          <div className="relative" data-aos="fade" data-aos-delay="100">
            <img
              src="/Pox_1.jpg"
              alt="Image 1"
              className="w-full h-auto rounded-lg"
            />
          </div>

          {/* Image 2 */}
          <div className="relative" data-aos="fade" data-aos-delay="200">
            <img
              src="/Pox_2.jpg"
              alt="Image 2"
              className="w-full h-auto rounded-lg"
            />
          </div>

          {/* Image 3 */}
          <div className="relative" data-aos="fade" data-aos-delay="300">
            <img
              src="/Pox_3.jpg"
              alt="Image 3"
              className="w-full h-auto rounded-lg"
            />
          </div>

          {/* Image 4 */}
          <div className="relative" data-aos="fade" data-aos-delay="400">
            <img
              src="/Pox_4.jpg"
              alt="Image 4"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
