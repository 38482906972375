import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./pages/Navbar";
import Home from "./pages/Home";
import About from "./pages/AboutPage";
import FlexibilityMasterclass from "./pages/FlexibilityMasterclass";
import MobilityMuse from "./pages/MobilityMuse";
import Resources from "./pages/Resources";
import Login from "./pages/Login";
import Checkout from "./pages/offers/checkout"; // Import the Checkout component correctly
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "aos/dist/aos.css";
import { ToastContainer, Slide } from "react-toastify"; // Add custom options
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation(); // Detect the current path

  // Hide Navbar on the checkout page only
  const showNavbar = location.pathname !== "/offers/checkout"; 

  return (
    <>
      {showNavbar && <Navbar />} {/* Conditionally render Navbar */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/flexibility-masterclass" element={<FlexibilityMasterclass />} />
        <Route path="/mobility-muse-masterclasses" element={<MobilityMuse />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/login" element={<Login />} />
        <Route path="/offers/checkout" element={<Checkout />} /> {/* Correct route */}
      </Routes>

      {/* Toast Container Configuration */}
      <ToastContainer 
        position="top-right" 
        autoClose={3000} 
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide} // Add a slide transition effect
      />
    </>
  );
}

// Wrap the App with the Router so useLocation works properly
export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
