import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const WhatYouWillLearn = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS animations
  }, []);

  return (
    <section className="bg-[#a6676a] py-20">
      <div className="container mx-auto px-6">
        <h1
          className="
            text-white 
            font-notoserif 
            font-light 
            text-4xl 
            md:text-[56px] 
            leading-[1.2] 
            mt-0 
            mb-8 
            text-center 
            tracking-normal 
            antialiased
          "
          style={{
            WebkitFontSmoothing: "antialiased",
            textRendering: "optimizeLegibility",
            textSizeAdjust: "100%",
          }}
          data-aos="fade-up"
        >
          Here's what you will learn
        </h1>

        {/* Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 pt-12 justify-items-center">
          {/* Part 1: Education */}
          <div
            className="
              bg-white 
              p-6 lg:p-[30px] 
              pt-[50px] 
              rounded-lg 
              shadow-lg 
              transition-all 
              duration-400 
              ease-in-out 
              text-[#333333] 
              max-w-[500px] w-full
            "
            data-aos="fade-right"
          >
            <strong
              className="
                text-center 
                text-xl 
                tracking-wider 
                font-bold 
                block 
                mb-2 
                text-[#333333] 
                font-raleway 
                leading-[1.6] 
                antialiased
              "
            >
              PART 1:
            </strong>
            <h4
              className="
                text-center 
                text-[#d88f96] 
                font-bird-and-thorn 
                font-light 
                leading-[1.2] 
                text-4xl 
                md:text-[80px] 
                tracking-normal 
                antialiased 
                mb-4
              "
            >
              Education
            </h4>
            <h3
              className="
                text-center 
                text-[#1d1d1d] 
                font-notoserif 
                font-light 
                text-2xl 
                md:text-[36px] 
                leading-[1.2] 
                mt-0 
                mb-4 
                tracking-normal 
                antialiased
              "
            >
              The Art & Science of Flexibility
            </h3>
            <p
              className="
                text-center 
                text-[#333333] 
                font-raleway 
                font-normal 
                text-lg 
                md:text-[18px] 
                leading-[1.6] 
                mt-0 
                mb-4 
                antialiased
              "
              style={{
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                textSizeAdjust: "100%",
              }}
            >
              A lecture-based immersion into the key strategies for the holistic
              Mind to Muscle blueprint of unlocking your highest stretching
              potential!
            </p>
            <ul className="space-y-4 custom-icon">
              {[
                "Basics of the Fascia-Nervous System matrix & its partnership with flexibility",
                "How the Brain & Nervous System dictate your flexibility potential + why everything you think you know about flexibility is wrong!",
                "Debunking Outdated & Ineffective Flexibility techniques (See ya Passive Stretching👋🏼)",
                "Why Active flexibility Training is key for Powerful Mobility in your dancing - Get ready to get noticed for it in the studio!",
                "The How & When of using Dynamic Stretching techniques to reach your dancing & flexibility goals faster",
                "Making Flexibility Inclusive! Safe Flexibility Training for Hyper-mobile vs Non-Hyper-mobile Dancers",
              ].map((item, index) => (
                <li key={index} className="relative flex items-start">
                  <i className="fas fa-check text-[#9d646b] text-2xl font-bold mr-4 mt-[4px]"></i>
                  {item}
                </li>
              ))}
            </ul>
          </div>

          {/* Part 2: Embodiment */}
          <div
            className="
              bg-white 
              p-6 lg:p-[30px] 
              pt-[50px] 
              rounded-lg 
              shadow-lg 
              transition-all 
              duration-400 
              ease-in-out 
              text-[#333333] 
              max-w-[500px] w-full
            "
            data-aos="fade-left"
          >
            <strong
              className="
                text-center 
                text-xl 
                tracking-wider 
                font-bold 
                block 
                mb-2 
                text-[#333333] 
                font-raleway 
                leading-[1.6] 
                antialiased
              "
            >
              PART 2:
            </strong>
            <h4
              className="
                text-center 
                text-[#d88f96] 
                font-bird-and-thorn 
                font-light 
                leading-[1.2] 
                text-4xl 
                md:text-[80px] 
                tracking-normal 
                antialiased 
                mb-4
              "
            >
              Embodiment
            </h4>
            <h3
              className="
                text-center 
                text-[#1d1d1d] 
                font-notoserif 
                font-light 
                text-2xl 
                md:text-[36px] 
                leading-[1.2] 
                mt-0 
                mb-4 
                tracking-normal 
                antialiased
              "
            >
              Fluid Flexibility Recalibration
            </h3>
            <p
              className="
                text-center 
                text-[#333333] 
                font-raleway 
                font-normal 
                text-lg 
                md:text-[18px] 
                leading-[1.6] 
                mt-0 
                mb-4 
                antialiased
              "
              style={{
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                textSizeAdjust: "100%",
              }}
            >
              A movement-based immersion into unlocking new levels of openness
              with your body.
            </p>
            <ul className="space-y-4 custom-icon">
              {[
                "3 full-length Yoga classes to embody & understand Active Mobility work for healthy joints & a happy nervous system",
                "Learn Key Exercises for bulletproof Mobility & Functional Flexibility (Higher kicks & more flexi tricks anyone?😉)",
                "Understand the holistic Use of breath to ease pain in stretching and go further into your stretches - Breathe deeper, Stretch deeper",
                "Exploring PNF, Isometric Stretching, Eccentric Loading, Dynamic Flowing, and understanding when to use them",
                "Build a new level of intuitive body awareness, control, strength, flexibility, and balance to support your dance technique & career longevity",
                "Receive a whole new Stretching routine to Finally claim the flexibility you desire! For yourself or your students.",
              ].map((item, index) => (
                <li key={index} className="relative flex items-start">
                  <i className="fas fa-check text-[#9d646b] text-2xl font-bold mr-4 mt-[4px]"></i>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="my-16">
          <h1
            className="
            text-white 
            font-notoserif 
            font-light 
            text-4xl 
            md:text-5xl 
            leading-[1.2] 
            mt-12 
            mb-3 
            text-center 
            tracking-normal 
            antialiased
          "
            style={{
              WebkitFontSmoothing: "antialiased",
              textRendering: "optimizeLegibility",
              textSizeAdjust: "100%",
            }}
          >
            Plus 3 Advanced Stretching Classes
          </h1>
          <p
            className="
                text-center 
                text-[#ffffff] 
                font-raleway 
                font-normal 
                text-lg 
                md:text-[18px] 
                leading-[1.6] 
                mt-0 
                mb-4 
                antialiased
              "
            style={{
              WebkitFontSmoothing: "antialiased",
              textRendering: "optimizeLegibility",
              textSizeAdjust: "100%",
            }}
          >
            I'll teach you my favorite targeted exercises to open up the range
            of motion in your hips, shoulders and spine!
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
            {/* Image 1 */}
            <div className="shadow-lg rounded-lg overflow-hidden">
              <img
                src="/UpperBody.webp"
                alt="Upper Body Flexibility"
                className="w-full h-auto object-cover"
              />
            </div>
            {/* Image 2 */}
            <div className="shadow-lg rounded-lg overflow-hidden">
              <img
                src="/MiddleSplits.webp"
                alt="Middle Splits Flexibility"
                className="w-full h-auto object-cover"
              />
            </div>

            {/* Image 3 */}
            <div className="shadow-lg rounded-lg overflow-hidden">
              <img
                src="/HealthyBack.webp"
                alt="Healthy Back Bend Mobility"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatYouWillLearn;
