import React from "react";

const InGoodHands = () => {
  return (
    <section
      className="relative py-16 bg-light-background"
      style={{
        backgroundImage: "url('/Good_Hands.jpg')", 
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingTop: "100px", // Added padding at the top
        position: "relative", 
        display: "block",
      }}
    >
      <div className="container mx-auto px-4">
        <div className="row flex flex-col items-center justify-center text-center max-w-2xl mx-auto">
          {/* Title Section */}
          <h3
            className="text-center text-4xl font-notoserif font-light leading-tight text-[#1d1d1d] mb-8"
            style={{
              lineHeight: "1.2", // Line height for close vertical spacing
              marginTop: "0", // Ensure no additional top margin
              marginBottom: "0.5rem", // Slight margin at the bottom
              WebkitFontSmoothing: "antialiased", // Smooth font rendering
              textRendering: "optimizeLegibility", // Optimized text rendering
            }}
          >
            You’re in{" "}
            <span
              style={{
                background:
                  "linear-gradient(transparent 96%, rgb(225, 162, 145) 96%)",
              }}
            >
              good hands
            </span>
            ,
          </h3>

          {/* First Paragraph */}
          <p
            className="text-base text-[#333333] leading-relaxed font-raleway mb-8"
            style={{
              marginTop: "0", // Remove top margin
              marginBottom: "1rem", // Consistent bottom margin
              fontSize: "18px", // Font size for paragraphs
              fontWeight: "400", // Paragraph font weight
              lineHeight: "1.6", // Line height for good readability
              letterSpacing: "normal", // Ensure no additional letter spacing
              WebkitFontSmoothing: "antialiased", // Smooth font rendering
              textRendering: "optimizeLegibility", // Improved text rendering
            }}
          >
            accommodating the unique needs of each individual dancer is my
            specialty. In my classes, you’ll be empowered with the anatomy info
            to feel safe, confident, and empowered in how you train.
          </p>

          {/* Second Paragraph */}
          <p
            className="text-base text-[#333333] leading-relaxed font-raleway"
            style={{
              marginTop: "0", // Remove top margin
              marginBottom: "1rem", // Consistent bottom margin
              fontSize: "18px", // Font size for paragraphs
              fontWeight: "400", // Paragraph font weight
              lineHeight: "1.6", // Line height for good readability
              letterSpacing: "normal", // Ensure no additional letter spacing
              WebkitFontSmoothing: "antialiased", // Smooth font rendering
              textRendering: "optimizeLegibility", // Improved text rendering
            }}
          >
            Don't wait until you're injured, burnt out, or struggling to invest
            in your well-being, you deserve to thrive, not just survive. If
            you're questioning your worth as a dancer, frustrated by your lack
            of progress, exhausted from trying to keep up with impossible
            standards, feel like you're always fighting your body or struggling
            with anxiety & low self-esteem, know that you are NOT alone, & there
            IS a better way!
          </p>
        </div>
      </div>
    </section>
  );
};

export default InGoodHands;
