import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return (
    <nav
      className="border-gray-200 dark:bg-gray-900"
      style={{ backgroundColor: "#FAFAFA" }}
    >
      <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
        <Link
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src="/YDA Logo.webp"
            className="h-10 yda-logo"
            alt="YDA Logo"
            style={{
              display: "block",
              width: "60px",
              maxWidth: "100%",
              height: "auto",
              border: 0,
              boxSizing: "inherit",
              overflowClipMargin: "content-box",
              overflow: "clip",
            }}
          />
        </Link>

        {/* Hamburger Icon (hidden in desktop) */}
        <div
          className={`hamburger md:hidden ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="hamburger__slices">
            <div className="hamburger__slice hamburger--slice-1"></div>
            <div className="hamburger__slice hamburger--slice-2"></div>
            <div className="hamburger__slice hamburger--slice-3"></div>
            <div className="hamburger__slice hamburger--slice-4"></div>
          </div>
        </div>

        {/* Menu Links */}
        <div className={`menu-container ${isOpen ? "open" : ""}`}>
          <div
            id="navbar-default"
            className="w-full md:flex md:items-center md:justify-end"
          >
            <ul className="font-medium flex flex-col p-0 mt-0 md:flex-row rtl:space-x-reverse">
              <li>
                <Link to="/" className="navbar-item">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="navbar-item">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/flexibility-masterclass" className="navbar-item">
                  FLEXIBILITY MASTERCLASS
                </Link>
              </li>
              <li>
                <Link to="/mobility-muse-masterclasses" className="navbar-item">
                  THE MOBILITY MUSE MASTERCLASS
                </Link>
              </li>
              <li>
                <Link to="/resources" className="navbar-item">
                  RESOURCES
                </Link>
              </li>
              <li>
                <Link to="/login" className="navbar-item">
                  LOG IN
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
