import React from "react";
import Slider from "react-slick";

const testimonials = [
  {
    text: (
      <>
        “This saved me! My ham strings are always so tight but after doing your
        video in just two days, there is so much improvement! ❤️💪🏼”
      </>
    ),
    name: "Maya",
  },
  {
    text: <>“My upper body has never felt this strong and flexible!”</>,
    name: "Justine",
  },
  {
    text: (
      <>
        “My biggest physical win has been finding ways to release my quads and
        my abs! {""}
        <span className="font-bold italic">
          It's really already opened up some spinal flexibility I never knew I
          had; crazy!
        </span>
        ”
      </>
    ),
    name: "Julie",
  },
  {
    text: (
      <>
        “Physically, I would say I'm much stronger in my end range already! I
        just am feeling pretty strong in general and much more {" "}
        <span className="font-bold italic">
          aware of my body in this whole new way.
        </span>
        ”
      </>
    ),
    name: "Jordan",
  },
  {
    text: (
      <>
        “I feel like my body has been more flexy and gooey with more ease.
        Flexibility hasn't really been an issue for me however stiffness and
        pain is. But lately I notice my body feels more supported in its end
        range and I'm making more - aesthetic - lines without more effort. It
        feels nice for my body to feel more spacious and mobile!”
      </>
    ),
    name: "Chelsea",
  },
  {
    text: (
      <>
        “I am super hypermobile and definitely didn't have the strength that I
        have now because now I know how to correctly strengthen and stretch my
        body!!”
      </>
    ),
    name: "Nadia",
  },
];

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 6000,
    customPaging: () => (
      <button>
        <span className="slick-dot-icon">
          <i className="fas fa-circle"></i>
        </span>
      </button>
    ),
  };

  return (
    <section
      className="relative bg-white py-16"
      style={{
        backgroundImage: "url('/Testimonial_Background.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Gold Line at the Start */}
      <div className="flex justify-center w-full mb-8">
        <img
          src="/gold_line_down.png"
          alt="Gold Line"
          className="w-[4px] h-[100px] sm:h-[200px] relative -mt-20 sm:-mt-40" // Halve height in mobile, full height in larger screens
          data-aos="fade-down"
          data-aos-duration="1000"
        />
      </div>

      {/* Testimonial Content */}
      <div className="container mx-auto text-center">
        {/* Main heading */}
        <h2 className="font-notoserif font-thin text-4xl lg:text-5xl text-gray-800 mb-2">
          Check out the results
        </h2>

        {/* Secondary text */}
        <h4
          className="font-bird-and-thorn text-center mt-8"
          style={{
            color: "#d88f96",
            lineHeight: "50px",
            fontSize: "70px",
            letterSpacing: "normal",
            textRendering: "optimizeLegibility",
          }}
        >
          of my methods
        </h4>

        {/* Carousel Container */}
        <div className="relative w-full max-w-4xl mx-auto">
          <div className="carousel-container relative">
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center text-center space-y-2 w-full lg:w-3/4 mx-auto px-8 md:px-8"
                >
                  {/* Gold Quote */}
                  <div className="w-full flex justify-center mb-2">
                    <img
                      src="/gold-quote.webp"
                      alt="Gold Quote"
                      className="w-[35px]"
                    />
                  </div>
                  <p className="text-lg text-gray-600 leading-relaxed max-w-3xl mx-auto">
                    {testimonial.text}
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
