import React from "react";
import FreeResources from "../components/Resources/FreeResources";
import Videos from "../components/Resources/Videos";
import Testimonials from "../components/Resources/Testimonials";
import AboutYDA from "../components/Resources/AboutYDA";
import Intentions from "../components/Resources/Intentions";
import Gallery from "../components/Resources/Gallery";
import Footer from "../components/Footer";

const Resources = () => {
  return (
    <div>
      <FreeResources />
      <Videos />
      <Testimonials />
      <AboutYDA />
      <Intentions />
      <Gallery />
      <Footer />
    </div>
  );
};

export default Resources;
