import React from "react";

const FreeResources = () => {
  return (
    <section
      className="relative py-16 lg:py-24"
      style={{
        backgroundImage: "url('/Resources_Background.jpg')", // Background image
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent repeating
        backgroundSize: "cover", // Cover the entire section with the image
      }}
    >
      {/* Overlay */}
      <div
        className="absolute inset-0"
        style={{
          backgroundColor: "rgba(239, 219, 210, 0.02)", // Light overlay color
          position: "absolute",
          width: "100%",
          height: "100%",
          left: "0",
          top: "0",
        }}
      ></div>

      {/* Container */}
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center">
          {/* Top Section */}
          <div
            className="text-center"
            style={{
              paddingTop: "80px", // Keep consistent padding for desktop
            }}
          >
            {/* "Free Online" Text */}
            <h1
              className="font-notoserif font-thin text-6xl lg:text-6xl"
              style={{
                color: "#fffaf8", // Light color for the heading text
                fontFamily: '"notoserif", sans-serif', // Font family
                lineHeight: "1.2", // Line height for good spacing
                letterSpacing: "normal", // No additional letter spacing
                textRendering: "optimizeLegibility", // Improved text rendering
                textAlign: "center", // Center the text
              }}
            >
              Free Online
            </h1>
          </div>

          {/* Bottom Section */}
          <div
            className="text-center mt-[-30px] lg:mt-[-40px]" // Adjust the negative margin for mobile and keep larger margin for desktop
            style={{
              paddingTop: "10px", // Keep the padding consistent across views
              paddingBottom: "15px", // Bottom padding for mobile
            }}
          >
            {/* "Resources" Text */}
            <h4
              className="font-bird-and-thorn text-6xl lg:text-7xl" // Keep larger font for mobile and desktop
              style={{
                color: "#1d1d1d", // Darker text color for the subheading
                fontWeight: "400", // Light font weight for h4
                lineHeight: "1.2", // Line height for spacing
                marginTop: "0", // Remove top margin
                marginBottom: "0.5rem", // Add some bottom margin
                textRendering: "optimizeLegibility", // Improve text rendering
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textAlign: "center", // Center the text
              }}
            >
              Resources
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreeResources;
