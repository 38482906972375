import React from "react";

const Hero = () => {
  return (
    <section
      className="relative w-full h-[350px] sm:h-[450px] lg:h-[350px] bg-cover bg-bottom flex items-center justify-center"
      style={{ backgroundImage: "url('/About_Hero.jpg')" }}
    >
      {/* Text Content */}
      <div className="relative z-10 text-center sm:text-right px-4 lg:px-10 lg:max-w-[60%] lg:ml-auto lg:mr-[35%] sm:mt-[-40px] lg:mt-[50px]">
        {/* "About" text */}
        <h1 className="font-notoserif font-thin text-white text-7xl sm:text-6xl lg:text-7xl mb-2 sm:mb-4 leading-none">
          About
        </h1>
        {/* "Roxie" text */}
        <h4 className="font-bird-and-thorn text-[#9d646b] text-9xl sm:text-6xl lg:text-[120px] leading-none relative sm:right-0 lg:right-[-90px] sm:mt-[-10px] lg:mt-[-20px]">
          Roxie
        </h4>
      </div>
    </section>
  );
};

export default Hero;
