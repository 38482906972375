import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#fafafa] py-8 text-center">
      <div className="container mx-auto flex flex-col items-center justify-center">
        {/* Logo */}
        <a href="/" className="mb-4">
          <img
            src="YDA Logo.webp"
            alt="Yoga for Dance Academy"
            className="w-28"
          />
        </a>

        {/* Copyright */}
        <span className="text-gray-700 mb-4">
          © 2024 Yoga for Dance Academy
        </span>

        {/* Social Icons */}
        <div className="flex space-x-4 mb-4">
          <a
            href="https://www.facebook.com/Yoga-for-Dancers-with-Roxie-Nebel-100236358181750/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center rounded-full text-[#9d646b]"
          >
            <i className="fab fa-facebook-f text-xl"></i>
          </a>
          <a
            href="https://www.instagram.com/yogini_on_the_rox/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center rounded-full text-[#9d646b]"
          >
            <i className="fab fa-instagram text-xl"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UCN6qYi--qH4iL7tZFOsLcow?view_as=subscriber"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center rounded-full text-[#9d646b]"
          >
            <i className="fab fa-youtube text-xl"></i>
          </a>
        </div>

        <aside className="text-sm text-[#efdbd2]">
            Created by Thomson Knoles
        </aside>
      </div>
    </footer>
  );
};

export default Footer;
