import React from "react";

const Videos = () => {
  return (
    <section
      className="section relative py-16 lg:py-24 bg-light-background"
      style={{
        backgroundImage: "url('/Video_Background.jpg')", // Use your background image
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent repeating
        backgroundSize: "cover", // Cover the entire section
        backgroundAttachment: "fixed", // Make the background fixed
      }}
    >
      {/* Gold Line Leading Element */}
      <div className="flex justify-center w-full mb-8">
        <img
          src="/gold_line_down.png"
          alt="Gold Line"
          className="w-[4px] h-[150px] sm:h-[250px] relative -mt-36 sm:-mt-48" // Adjusted negative margin to move the line up
          data-aos="fade-down"
          data-aos-duration="1000"
        />
      </div>

      <div className="container mx-auto px-4">
        {/* Added more gap between the video elements */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
          {/* A Dancer's Dialogue Vlogs */}
          <div className="text-center">
            <h3
              className="font-notoserif text-gray-800"
              style={{
                fontSize: "36px", // Desktop font size
                fontWeight: "300", // Light font weight
                lineHeight: "1.2", // Adjusted line height
                marginTop: "0", // No top margin
                marginBottom: "0.5rem", // Bottom margin for spacing
                letterSpacing: "normal", // No extra letter spacing
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Better text rendering
              }}
            >
              A Dancer's
            </h3>
            <p
              className="text-sm lg:text-lg tracking-wide uppercase"
              style={{
                textAlign: "center",
                letterSpacing: "0.2em", // Increased letter spacing
                fontSize: "21px", // Font size for all <p> elements
                marginTop: "0",
                marginBottom: "1rem",
                color: "#333333", // Default text color
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Improved text rendering
                fontFamily: "Raleway", // Font family for <p> elements
                lineHeight: "1.6", // Adjusted line height for readability
              }}
            >
              DIALOGUE VLOGS
            </p>
            <hr
              style={{
                height: "2px",
                backgroundColor: "#E1A291", // Color of the line
                border: "none",
                width: "50%", // Adjust width to 50% of its container
                margin: "0.5em auto", // Center it
              }}
            />
            <div className="block box-shadow-medium background-light">
              <div
                className="responsive-video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%", // 16:9 aspect ratio
                  height: "0",
                  overflow: "hidden",
                  maxWidth: "100%",
                  backgroundImage:
                    "url(https://i.ytimg.com/vi/GITvoiO2fzI/sddefault.jpg)",
                  backgroundSize: "cover", // Cover the entire container
                  backgroundPosition: "center", // Center the background image
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/GITvoiO2fzI"
                  title="A Dancer's Dialogue Vlog"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
            </div>
          </div>

          {/* Yoga Classes */}
          <div className="text-center">
            <h3
              className="font-notoserif text-gray-800"
              style={{
                fontSize: "36px", // Desktop font size
                fontWeight: "300", // Light font weight
                lineHeight: "1.2", // Adjusted line height
                marginTop: "0", // No top margin
                marginBottom: "0.5rem", // Bottom margin for spacing
                letterSpacing: "normal", // No extra letter spacing
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Better text rendering
              }}
            >
              Yoga
            </h3>
            <p
              className="text-sm lg:text-lg tracking-wide uppercase"
              style={{
                textAlign: "center",
                letterSpacing: "0.2em", // Increased letter spacing
                fontSize: "21px", // Font size for all <p> elements
                marginTop: "0",
                marginBottom: "1rem",
                color: "#333333", // Default text color
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Improved text rendering
                fontFamily: "Raleway", // Font family for <p> elements
                lineHeight: "1.6", // Adjusted line height for readability
              }}
            >
              CLASSES
            </p>
            <hr
              style={{
                height: "2px",
                backgroundColor: "#E1A291", // Color of the line
                border: "none",
                width: "50%", // Adjust width to 50% of its container
                margin: "0.5em auto", // Center it
              }}
            />
            <div className="block box-shadow-medium background-light">
              <div
                className="responsive-video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%", // 16:9 aspect ratio
                  height: "0",
                  overflow: "hidden",
                  maxWidth: "100%",
                  backgroundImage:
                    "url(https://i.ytimg.com/vi/1acsTGF4KSU/sddefault.jpg)",
                  backgroundSize: "cover", // Cover the entire container
                  backgroundPosition: "center", // Center the background image
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/1acsTGF4KSU"
                  title="Yoga Classes"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
            </div>
          </div>

          {/* Wellness & Anatomy */}
          <div className="text-center">
            <h3
              className="font-notoserif text-gray-800"
              style={{
                fontSize: "36px", // Desktop font size
                fontWeight: "300", // Light font weight
                lineHeight: "1.2", // Adjusted line height
                marginTop: "0", // No top margin
                marginBottom: "0.5rem", // Bottom margin for spacing
                letterSpacing: "normal", // No extra letter spacing
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Better text rendering
              }}
            >
              Wellness & Anatomy
            </h3>
            <p
              className="text-sm lg:text-lg tracking-wide uppercase"
              style={{
                textAlign: "center",
                letterSpacing: "0.2em", // Increased letter spacing
                fontSize: "21px", // Font size for all <p> elements
                marginTop: "0",
                marginBottom: "1rem",
                color: "#333333", // Default text color
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Improved text rendering
                fontFamily: "Raleway", // Font family for <p> elements
                lineHeight: "1.6", // Adjusted line height for readability
              }}
            >
              WELLNESS & ANATOMY
            </p>
            <hr
              style={{
                height: "2px",
                backgroundColor: "#E1A291", // Color of the line
                border: "none",
                width: "50%", // Adjust width to 50% of its container
                margin: "0.5em auto", // Center it
              }}
            />
            <div className="block box-shadow-medium background-light">
              <div
                className="responsive-video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%", // 16:9 aspect ratio
                  height: "0",
                  overflow: "hidden",
                  maxWidth: "100%",
                  backgroundImage:
                    "url(https://i.ytimg.com/vi/35c0E4I3n0Q/sddefault.jpg)",
                  backgroundSize: "cover", // Cover the entire container
                  backgroundPosition: "center", // Center the background image
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/35c0E4I3n0Q"
                  title="Wellness & Anatomy"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
            </div>
          </div>

          {/* Dance Films */}
          <div className="text-center">
            <h3
              className="font-notoserif text-gray-800"
              style={{
                fontSize: "36px", // Desktop font size
                fontWeight: "300", // Light font weight
                lineHeight: "1.2", // Adjusted line height
                marginTop: "0", // No top margin
                marginBottom: "0.5rem", // Bottom margin for spacing
                letterSpacing: "normal", // No extra letter spacing
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Better text rendering
              }}
            >
              Dance
            </h3>
            <p
              className="text-sm lg:text-lg tracking-wide uppercase"
              style={{
                textAlign: "center",
                letterSpacing: "0.2em", // Increased letter spacing
                fontSize: "21px", // Font size for all <p> elements
                marginTop: "0",
                marginBottom: "1rem",
                color: "#333333", // Default text color
                WebkitFontSmoothing: "antialiased", // Smooth font rendering
                textRendering: "optimizeLegibility", // Improved text rendering
                fontFamily: "Raleway", // Font family for <p> elements
                lineHeight: "1.6", // Adjusted line height for readability
              }}
            >
              FILMS
            </p>
            <hr
              style={{
                height: "2px",
                backgroundColor: "#E1A291", // Color of the line
                border: "none",
                width: "50%", // Adjust width to 50% of its container
                margin: "0.5em auto", // Center it
              }}
            />
            <div className="block box-shadow-medium background-light">
              <div
                className="responsive-video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%", // 16:9 aspect ratio
                  height: "0",
                  overflow: "hidden",
                  maxWidth: "100%",
                  backgroundImage:
                    "url(https://i.ytimg.com/vi/30ouE1rVQW8/sddefault.jpg)",
                  backgroundSize: "cover", // Cover the entire container
                  backgroundPosition: "center", // Center the background image
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/30ouE1rVQW8"
                  title="Dance Films"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Videos;
