import React from "react";
import Hero from "../components/Home/Hero";
import About from "../components/Home/About";
import Services from "../components/Home/Services";
import Youtube from "../components/Home/Youtube";
import Testimonial from "../components/Home/Testimonial";
import Images from "../components/Home/Images";
import Connect from "../components/Connect";
import Footer from "../components/Footer";

function Home() {
  return (
    <div>
      <Hero />
      <About />
      <Services />
      <Youtube />
      <Testimonial />
      <Images />
      <Connect />
      <Footer />
    </div>
  );
}

export default Home;
