import React from "react";

const Creds = () => {
  return (
    <section
      className="section-background bg-no-repeat bg-cover py-16 lg:py-28"
      style={{ backgroundImage: "url('/creds_background.jpg')" }}
    >
      <div className="container mx-auto px-4">
        {/* Mobile Image Section */}
        <div className="block lg:hidden mb-8">
          <img
            src="https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/2152085978/settings_images/9RhjDgO6QfKTollgWUxx_G1PnXqgSDG8kMYjm8kvU_25.jpg"
            alt="Roxie performing"
            className="rounded-lg w-full object-cover"
          />
        </div>

        <div className="flex flex-col lg:flex-row justify-between items-start">
          {/* Text Section */}
          <div className="lg:w-1/2 space-y-4">
            <h2
              className="text-center text-[#9d646b] font-notoserif font-light leading-tight"
              style={{
                fontSize: "42px",
                lineHeight: "1.2",
                letterSpacing: "normal",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
              }}
            >
              What are my Creds?
            </h2>
            <p
              className="text-center text-[#333333] font-raleway leading-relaxed"
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "1.6",
                marginTop: "0",
                marginBottom: "1rem",
                letterSpacing: "normal",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
              }}
            >
              Well I’ve been a certified yoga instructor since 2018
            </p>

            <div className="flex flex-col lg:flex-row justify-between lg:space-x-12">
              {/* Yoga Certifications */}
              <div className="space-y-4 lg:w-1/2">
                <strong
                  className="text-[#333333] font-raleway"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "1.6",
                    letterSpacing: "normal",
                    WebkitFontSmoothing: "antialiased",
                    textRendering: "optimizeLegibility",
                  }}
                >
                  With additional yoga certifications in:
                </strong>
                <ul className="list-none space-y-2">
                  <li className="flex items-start">
                    <span className="text-[#e1a291]">✔</span>
                    <span className="ml-2">
                      Therapeutic Alignment Essentials of Yoga
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#e1a291]">✔</span>
                    <span className="ml-2">Yoga for Back Care</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#e1a291]">✔</span>
                    <span className="ml-2">Yoga for Hyper mobility</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#e1a291]">✔</span>
                    <span className="ml-2">
                      Yoga Anatomy & the Breathing Body
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#e1a291]">✔</span>
                    <span className="ml-2">Restorative Yoga</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#e1a291]">✔</span>
                    <span className="ml-2">Children's Yoga</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#e1a291]">✔</span>
                    <span className="ml-2">The Moon Path Women’s Yoga</span>
                  </li>
                </ul>
              </div>

              {/* Non-Yoga Certifications */}
              <div className="space-y-4 lg:w-1/2 mt-8 lg:mt-0">
                <strong
                  className="text-[#333333] font-raleway"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "1.6",
                    letterSpacing: "normal",
                    WebkitFontSmoothing: "antialiased",
                    textRendering: "optimizeLegibility",
                  }}
                >
                  My Non-Yoga Certifications include:
                </strong>
                <ul className="list-none space-y-2">
                  <li className="flex items-start">
                    <span className="text-[#e1a291]">✔</span>
                    <span className="ml-2">
                      Progressing Ballet Technique (PBT)
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#e1a291]">✔</span>
                    <span className="ml-2">
                      The Phoenix Path Trauma Informed Practitioner
                      Certification
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Creds;
