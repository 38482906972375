import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d88f96', // Pink border on focus
            boxShadow: '0 0 5px #d88f96', // Pink shadow
          },
          '& input:focus': {
            outline: 'none', // Remove blue outline
          },
        },
      },
    },
  },
});

export default theme;
