import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Images = () => {
  const images = [
    {
      src: "Crystals.jpg",
      overlayColor: "rgba(225, 162, 145, 0.575)",
      delay: "200",
    },
    {
      src: "Feather.jpg",
      overlayColor: "rgba(157, 100, 107, 0.535)",
      delay: "400",
    },
    {
      src: "Pussywillow.jpg",
      overlayColor: "rgba(239, 219, 210, 0.62)",
      delay: "600",
    },
    {
      src: "Hat.jpg",
      overlayColor: "rgba(255, 250, 248, 0.61)",
      delay: "800",
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: false, // Ensures animation triggers every time the element comes into view
      offset: 50, // Triggers slightly before the element reaches the viewport
    });
  }, []);

  return (
    <section className="section relative">
      <div className="grid sm:grid-cols-1 lg:grid-cols-4 gap-0">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-none"
            data-aos="fade"
            data-aos-delay={image.delay}
            data-aos-anchor-placement="top-bottom"
          >
            <img
              className="w-full h-auto transition-transform duration-300 ease-in-out"
              src={image.src}
              alt={`Image ${index + 1}`}
            />
            <div
              className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300"
              style={{ backgroundColor: image.overlayColor }}
            ></div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Images;
