import React from "react";

const Connect = () => {
  return (
    <section className="bg-[#f4dfdb] py-8 flex flex-col items-center justify-center text-center">
      {/* Heading */}
      <h4 className="font-bird-and-thorn text-5xl font-semibold text-black mb-4">
        Let's connect!
      </h4>

      {/* Subtitle */}
      <p className="text-lg mb-4">
        Follow me on Instagram for more dancers wellness content and trainings
      </p>

      {/* Instagram Handle */}
      <p className="text-base font-semibold text-[#9d646b]">
        <a
          href="https://www.instagram.com/yogini_on_the_rox/"
          target="_blank"
          rel="noopener noreferrer"
        >
          @yogini_on_the_rox
        </a>
      </p>

      {/* Social Icon */}
      <div className="mt-8">
        <a
          href="https://www.instagram.com/yogini_on_the_rox/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-10 h-10 flex items-center justify-center rounded-full bg-[#e1a291] text-white"
        >
          <i className="fab fa-instagram text-xl"></i>
        </a>
      </div>
    </section>
  );
};

export default Connect;
