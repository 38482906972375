import React from "react";

const Intentions = () => {
  return (
    <section
      className="relative py-16 lg:py-24 bg-fixed"
      style={{
        backgroundImage: "url('/intentional%20ferns.jpg')", // Ensure correct image path
        backgroundSize: "cover", // Cover the entire section
        backgroundPosition: "center", // Center the background image
        backgroundAttachment: "fixed", // Make the background fixed
      }}
    >
      <div className="container mx-auto px-4">
        {/* Main Heading with Text Container */}
        <div className="flex justify-center lg:justify-start mb-8">
          <div className="text-center max-w-lg lg:max-w-3xl lg:ml-24">
            <h3
              className="font-notoserif text-4xl font-light leading-tight mb-6" // Adjust font size responsively
              style={{
                color: "#1d1d1d", // Text color
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
              }}
            >
              Each Yoga for Dancers class is rooted in these{" "}
              <span
                style={{
                  background:
                    "linear-gradient(transparent 96%, rgb(225, 162, 145) 96%)",
                }}
              >
                six powerful intentions
              </span>
            </h3>
          </div>
        </div>

        {/* Intentions List */}
        <div className="space-y-8 lg:max-w-3xl lg:ml-16">
          <div className="flex items-start lg:items-center space-x-6">
            <h1
              className="inline-block font-notoserif font-light text-6xl leading-tight"
              style={{
                color: "#9d646b",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                lineHeight: "1.2",
                letterSpacing: "normal",
              }}
            >
              01.
            </h1>
            <div>
              <strong
                className="text-xl"
                style={{
                  color: "#333333", // Dark text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                LEARN
              </strong>
              <p
                className="text-lg text-gray-600"
                style={{
                  marginTop: "0", // No margin at the top
                  marginBottom: "1rem", // Space below the paragraph
                  fontFamily: "Raleway", // Use Raleway font
                  fontWeight: "400", // Set font weight
                  lineHeight: "1.6", // Adjust line height
                  color: "#333333", // Text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                How to utilize the breath as a tool to vitalize and energize the
                body.
              </p>
            </div>
          </div>

          <div className="flex items-start lg:items-center space-x-6">
            <h1
              className="inline-block font-notoserif font-light text-6xl leading-tight"
              style={{
                color: "#9d646b",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                lineHeight: "1.2",
                letterSpacing: "normal",
              }}
            >
              02.
            </h1>
            <div>
              <strong
                className="text-xl"
                style={{
                  color: "#333333", // Dark text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                UNLOCK
              </strong>
              <p
                className="text-lg text-gray-600"
                style={{
                  marginTop: "0", // No margin at the top
                  marginBottom: "1rem", // Space below the paragraph
                  fontFamily: "Raleway", // Use Raleway font
                  fontWeight: "400", // Set font weight
                  lineHeight: "1.6", // Adjust line height
                  color: "#333333", // Text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                The fascia system for greater freedom, flexibility, and range of
                motion.
              </p>
            </div>
          </div>

          <div className="flex items-start lg:items-center space-x-6">
            <h1
              className="inline-block font-notoserif font-light text-6xl leading-tight"
              style={{
                color: "#9d646b",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                lineHeight: "1.2",
                letterSpacing: "normal",
              }}
            >
              03.
            </h1>
            <div>
              <strong
                className="text-xl"
                style={{
                  fontWeight: "700", // Strong font weight
                  color: "#333333", // Dark text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                REWIRE
              </strong>
              <p
                className="text-lg text-gray-600"
                style={{
                  marginTop: "0", // No margin at the top
                  marginBottom: "1rem", // Space below the paragraph
                  fontFamily: "Raleway", // Use Raleway font
                  fontWeight: "400", // Set font weight
                  lineHeight: "1.6", // Adjust line height
                  color: "#333333", // Text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                Healthier movement habits to support joint longevity and injury
                prevention.
              </p>
            </div>
          </div>

          <div className="flex items-start lg:items-center space-x-6">
            <h1
              className="inline-block font-notoserif font-light text-6xl leading-tight"
              style={{
                color: "#9d646b",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                lineHeight: "1.2",
                letterSpacing: "normal",
              }}
            >
              04.
            </h1>
            <div>
              <strong
                className="text-xl"
                style={{
                  fontWeight: "700", // Strong font weight
                  color: "#333333", // Dark text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                CULTIVATE
              </strong>
              <p
                className="text-lg text-gray-600"
                style={{
                  marginTop: "0", // No margin at the top
                  marginBottom: "1rem", // Space below the paragraph
                  fontFamily: "Raleway", // Use Raleway font
                  fontWeight: "400", // Set font weight
                  lineHeight: "1.6", // Adjust line height
                  color: "#333333", // Text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                Full body proprioceptive awareness to master articulation of
                movement and give your dancing a refined edge.
              </p>
            </div>
          </div>

          <div className="flex items-start lg:items-center space-x-6">
            <h1
              className="inline-block font-notoserif font-light text-6xl leading-tight"
              style={{
                color: "#9d646b",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                lineHeight: "1.2",
                letterSpacing: "normal",
              }}
            >
              05.
            </h1>
            <div>
              <strong
                className="text-xl"
                style={{
                  fontWeight: "700", // Strong font weight
                  color: "#333333", // Dark text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                ACTIVATE
              </strong>
              <p
                className="text-lg text-gray-600"
                style={{
                  marginTop: "0", // No margin at the top
                  marginBottom: "1rem", // Space below the paragraph
                  fontFamily: "Raleway", // Use Raleway font
                  fontWeight: "400", // Set font weight
                  lineHeight: "1.6", // Adjust line height
                  color: "#333333", // Text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                Down to the deepest layers of the core for 360 degrees of
                strength and spine protection.
              </p>
            </div>
          </div>

          <div className="flex items-start lg:items-center space-x-6">
            <h1
              className="inline-block font-notoserif font-light text-6xl leading-tight"
              style={{
                color: "#9d646b",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                lineHeight: "1.2",
                letterSpacing: "normal",
              }}
            >
              06.
            </h1>
            <div>
              <strong
                className="text-xl"
                style={{
                  fontWeight: "700", // Strong font weight
                  color: "#333333", // Dark text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                EXPERIENCE
              </strong>
              <p
                className="text-lg text-gray-600"
                style={{
                  marginTop: "0", // No margin at the top
                  marginBottom: "1rem", // Space below the paragraph
                  fontFamily: "Raleway", // Use Raleway font
                  fontWeight: "400", // Set font weight
                  lineHeight: "1.6", // Adjust line height
                  color: "#333333", // Text color
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                A deep sense of empowerment in your worth as an artist to build
                self-love and confidence that will make you shine on and off the
                stage!
              </p>
            </div>
          </div>
          {/* Gold Line Leading Element */}
          <div className="flex flex-col items-center w-full mb-8">
            <img
              src="/gold_line_down.png"
              alt="Gold Line"
              className="w-[4px] relative"
              data-aos="fade-down"
              data-aos-duration="1000"
            />
             <a
              href="#"
              className="inline-block bg-gradient-to-r from-yellow-400 to-yellow-600 text-white text-lg py-3 px-8 mt-6 shadow-lg transition duration-300 ease-in-out hover:shadow-xl rounded-xl font-light"
            >
              GET STARTED
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intentions;
