import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const FlexibilityHero = () => {
  return (
    <section
      className="relative w-full bg-cover bg-center py-16 lg:py-24"
      style={{
        backgroundImage: "url('/Flex_Hero.jpg')",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Overlay */}
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(255, 250, 248, 0.38)" }}
      ></div>

      <div className="container relative z-10 mx-auto text-center">
        <h4
          className="text-5xl md:text-6xl lg:text-8xl font-bird-and-thorn leading-none break-words"
          style={{
            marginTop: "15px",
            textAlign: "center",
            fontWeight: "400",
            color: "#1d1d1d",
            lineHeight: "1.2",
          }}
        >
          Flexibility Master Class:
        </h4>
        <h1 className="text-2xl lg:text-4xl font-notoserif font-light text-[#1d1d1d] leading-tight mt-6">
          The{" "}
          <span
            style={{
              background:
                "linear-gradient(transparent 96%, rgb(225, 162, 145) 96%)",
            }}
          >
            Mind to Muscle Blueprint
          </span>{" "}
          of Fluid Flexibility
        </h1>

        {/* Link to Checkout Page */}
        <Link to="/offers/checkout">
          <button
            className="inline-block bg-gradient-to-r from-yellow-400 to-yellow-600 text-white text-lg py-3 px-8 mt-16 shadow-lg transition duration-300 ease-in-out hover:shadow-xl rounded-xl font-light"
          >
            START STRETCHING TODAY
          </button>
        </Link>
      </div>
    </section>
  );
};

export default FlexibilityHero;
