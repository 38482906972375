import React from "react";
import FlexibilityHero from "../components/FlexibilityMasterclass/FlexibilityHero";
import FlexibilityDescription from "../components/FlexibilityMasterclass/FlexibilityDescription";
import MeetRoxie from "../components/FlexibilityMasterclass/MeetRoxie";
import WhatYouWillLearn from "../components/FlexibilityMasterclass/WhatYouWillLearn";
import ResultsSlider from "../components/FlexibilityMasterclass/ResultsSlider";
import Footer from "../components/Footer";

const FlexibilityMasterclass = () => {
  return (
    <div>
      <FlexibilityHero />
      <FlexibilityDescription />
      <MeetRoxie />
      <WhatYouWillLearn />
      <ResultsSlider />
      <Footer />
    </div>
  );
};

export default FlexibilityMasterclass;
