import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const MeetRoxie = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Animation will only happen once
    });
  }, []);

  return (
    <section
      className="section relative w-full flex flex-col items-center justify-center p-0 bg-cover bg-center bg-no-repeat bg-fixed min-h-[100vh]"
      style={{ backgroundImage: "url('/About_Background.jpg')" }}
    >
      {/* Container for Text and Image */}
      <div className="container mx-auto px-6 flex flex-col lg:flex-row items-center justify-between gap-6 lg:gap-12">
        {/* Image Section */}
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-start">
          <div className="relative rounded-full overflow-hidden w-[400px] lg:w-[500px]">
            <img
              src="/Roxie_Pose.webp"
              alt="Roxie"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>

        {/* Text Section */}
        <div className="w-full lg:w-1/2 text-left lg:pl-8">
          <h4
            className="font-bird-and-thorn font-light text-[#9d646b] leading-tight tracking-normal antialiased text-7xl md:text-[80px] md:ml-[-30px] mb-4"
            data-aos="fade-in"
            data-aos-delay="300"
          >
            <span>Hey, it's Roxie!</span>
          </h4>

          <p
            className="mt-0 mb-4 text-lg text-[#1d1d1d] leading-[1.6] font-raleway"
            style={{
              WebkitFontSmoothing: "antialiased",
              textRendering: "optimizeLegibility",
            }}
          >
            I’ve used yoga as a tool for years to feel effortlessly limber in my
            dancing and free to access advanced bendy tricks on the pole. After
            I injured my back while dancing in 2017, I completely lost my
            flexibility (as in couldn't even touch my toes!) All the techniques
            I'll share with you are the things that helped me regain my
            flexibility in a matter of months after recovery!
          </p>

          <p
            className="mt-0 mb-4 text-lg text-[#1d1d1d] leading-[1.6] font-raleway"
            style={{
              WebkitFontSmoothing: "antialiased",
              textRendering: "optimizeLegibility",
            }}
          >
            In this course, I'll be equipping you with the necessary tools,
            maps, & frameworks so you can succeed in reaching your flexibility
            goals faster, get the flexy tricks you dream of, and keep your body
            safe in the process – time to work smarter, not harder ;)
          </p>
        </div>
      </div>

      {/* Large Image Underneath the Entire Component */}
      <div className="w-full mt-12 px-6 lg:px-0">
        <img
          src="/MeetRoxie_Graphic.png"
          alt="Flexibility Promotion"
          className="w-[95%] lg:w-[80%] xl:w-[70%] h-auto mx-auto"
          style={{
            maxWidth: "1400px", // Limit the max size on large screens
          }}
        />
      </div>
      {/* CTA Button */}
      <div className="mt-12 pb-16">
        <a
          href="/about" // Update this link to the correct destination
          className="inline-block bg-gradient-to-r from-yellow-400 to-yellow-600 text-white text-lg py-3 px-8 shadow-lg transition duration-300 ease-in-out hover:shadow-xl rounded-xl font-light"
        >
          GET INSTANT ACCESS
        </a>
      </div>
    </section>
  );
};

export default MeetRoxie;
