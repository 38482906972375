import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  Link,
  Tooltip,
} from "@mui/material";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// Initialize Stripe with public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [waiverAccepted, setWaiverAccepted] = useState(false);
  const [email, setEmail] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);

  const handleWaiverChange = useCallback((e) => {
    setWaiverAccepted(e.target.checked);
    setError(null);
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      setError(null);

      if (!navigator.onLine) {
        toast.error("You are offline. Please check your network connection.");
        setLoading(false);
        return;
      }

      if (!waiverAccepted) {
        toast.error("Please accept the waiver to proceed.");
        setLoading(false);
        return;
      }

      if (!email.trim()) {
        toast.error("Email is required.");
        setLoading(false);
        return;
      }

      if (!cardComplete) {
        toast.error("Please complete your card information.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch("/api/checkout", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });

        if (!response.ok) {
          const message = await response.text();
          throw new Error(message || "Failed to create payment intent.");
        }

        const { clientSecret } = await response.json();

        const { error: stripeError, paymentIntent } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: { email },
            },
          });

        if (stripeError) throw stripeError;

        if (paymentIntent.status === "succeeded") {
          toast.success("Payment successful!", { autoClose: 2000 });
          setTimeout(() => {
            setEmail("");
            setWaiverAccepted(false);
            setCouponCode("");
            window.location.href = "/success"; // Redirect on success
          }, 2000);
        }
      } catch (err) {
        console.error("Error:", err);
        toast.error(err.message || "Something went wrong.");
        setError(err.message);
      } finally {
        setLoading(false);
      }
    },
    [waiverAccepted, email, elements, stripe, cardComplete]
  );

  const canSubmit = waiverAccepted && email.trim() && cardComplete && !loading;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        padding: "30px",
        border: "1px solid #e5e9e9",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          paddingBottom: "10px",
          borderBottom: "1px solid #e5e9e9",
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            fontSize: "24px",
            color: "#000",
            textAlign: "left",
          }}
        >
          $25.00 USD
        </Typography>
      </Box>

      {/* Coupon Code Field */}
      <TextField
        value={couponCode}
        onChange={(e) => setCouponCode(e.target.value)}
        fullWidth
        variant="outlined"
        placeholder="Coupon Code" // Use placeholder instead of label
        margin="normal"
        InputProps={{
          endAdornment: (
            <Button
              aria-label="Apply Coupon"
              variant="text"
              size="small"
              disabled={!couponCode.trim()}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                padding: "8px 12px",
                borderRadius: "0 6px 6px 0", // Slightly rounded on the right corner
                borderLeft: "1px solid #e0e0e0",
                backgroundColor: "transparent",
                color: "#000",
                minWidth: "fit-content",
                "&:hover": {
                  backgroundColor: "#f1f1f1",
                },
              }}
            >
              Apply
            </Button>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            display: "flex",
            paddingRight: 0,
            borderRadius: "6px", // Subtly rounded corners
            transition: "border-color 0.2s ease-in-out",
            "&:focus-within": {
              borderColor: "#d88f96",
              borderWidth: "1px",
              outline: "none",
              boxShadow: "none !important",
            },
          },
          "& input": {
            padding: "10px 12px",
            outline: "none",
          },
          "& fieldset": {
            borderWidth: "1px",
            borderColor: "#e0e0e0",
          },
        }}
      />

      {/* Name Field */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Full Name" // Placeholder text for the name field
        margin="normal"
        sx={{
          "& .MuiOutlinedInput-root": {
            display: "flex",
            borderRadius: "6px", // Consistent subtle rounding
            transition: "border-color 0.2s ease-in-out",
            "&:focus-within": {
              borderColor: "#d88f96", // Pink border on focus
              borderWidth: "1px",
              outline: "none",
              boxShadow: "none !important", // Remove unwanted shadow
            },
          },
          "& input": {
            padding: "10px 12px", // Adjust padding for consistency
            outline: "none", // No blue outline
          },
          "& fieldset": {
            borderWidth: "1px",
            borderColor: "#e0e0e0",
          },
        }}
      />

      {/* Log in Link Positioned Above Email Field */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "1px", // Adjusting placement closer to the email field
          marginBottom: "-15px",
        }}
      >
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: "14px",
            color: "#0070f3", // Default blue color
            padding: "10px 12px", // Increase clickable area
            borderRadius: "6px", // Slight rounding for consistent design
            "&:hover": {
              backgroundColor: "#f1f1f1", // Light gray background on hover
            },
            "&:active": {
              color: "#d88f96", // Change color to pink on click
            },
          }}
        >
          Log in
        </Link>
      </Box>

      {/* Email Address Field */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Email Address" // Use placeholder instead of label
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={loading}
        sx={{
          "& .MuiOutlinedInput-root": {
            display: "flex",
            borderRadius: "6px", // Subtly rounded corners
            transition: "border-color 0.2s ease-in-out",
            "&:focus-within": {
              borderColor: "#d88f96",
              borderWidth: "1px",
              outline: "none",
              boxShadow: "none !important",
            },
          },
          "& input": {
            padding: "10px 12px",
            outline: "none",
          },
          "& fieldset": {
            borderWidth: "1px",
            borderColor: "#e0e0e0",
          },
        }}
      />

      {/* Credit Card Field */}
      <Box
        sx={{
          padding: "12px",
          border: "1px solid #c7cfd8",
          borderRadius: "8px",
          marginTop: "10px",
          backgroundColor: "#fff",
        }}
      >
        <CardElement
          options={{
            style: {
              base: {
                iconColor: "#999999",
                color: "#151515",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Open Sans, sans-serif",
                "::placeholder": { color: "#999999" },
              },
              invalid: {
                iconColor: "#e2422d",
                color: "#e2422d",
              },
            },
          }}
          onChange={({ complete, error }) => {
            setCardComplete(complete);
            setError(error ? error.message : null);
          }}
          disabled={loading}
        />
      </Box>

      {/* Form Options */}
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              padding: "4px", // Reduce padding around checkbox
              "& .MuiSvgIcon-root": {
                fontSize: "18px", // Reduce the size of the checkbox icon
              },
            }}
          />
        }
        label={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            Store this card for future purchases
            <Tooltip
              title="Your payment information will be stored on a secure server for future purchases"
              arrow
            >
              <HelpOutlineIcon
                sx={{
                  fontSize: "16px",
                  marginLeft: "8px", // Spacing between label and icon
                  cursor: "help", // Cursor style for tooltip
                  color: "#556370", // Match icon color
                }}
              />
            </Tooltip>
          </Box>
        }
        sx={{
          marginTop: "10px",
          color: "#555",
          "& .MuiFormControlLabel-label": {
            fontSize: "13px", // Match font size to the design
            fontWeight: 400,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          },
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            sx={{
              padding: "4px", // Reduce padding
              "& .MuiSvgIcon-root": {
                fontSize: "18px", // Smaller checkbox icon
              },
            }}
          />
        }
        label="Subscribe to our email list."
        sx={{
          color: "#555",
          "& .MuiFormControlLabel-label": {
            fontSize: "13px", // Match font size
            fontWeight: 400,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          },
        }}
      />

      {/* Waiver Text Box */}
      <Box
        sx={{
          maxHeight: "100px",
          overflowY: "scroll",
          border: "1px solid #e0e0e0",
          borderRadius: "6px",
          padding: "10px",
          marginTop: "15px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography
          variant="body2"
          sx={{ whiteSpace: "pre-line", fontSize: "14px", color: "#555" }}
        >
          I understand that yoga fitness and pole dance includes physically
          rigorous movements. As is the case with any physical activity, the
          risk of injury, even serious or disabling, is always present and
          cannot be entirely eliminated. If I experience any pain or discomfort,
          I will listen to my body, discontinue the activity, and ask for
          support from the instructor. I acknowledge that I may rest at any time
          during the class. I assume full responsibility for any and all
          damages, which may incur through participation. Yoga is not a
          substitute for Medical attention, examination, diagnosis or treatment.
          Yoga and Pole dancing is not recommended or safe under certain medical
          conditions. By signing I affirm that I am in good physical condition
          and have no physical or medical conditions, disabilities or
          limitations that would be aggravated by, or cause me to be injured by
          active, vigorous dance, physical movement, and yoga training classes.
          I confirm that I am aware of all my physical and medical limitations
          and will not exceed them. In addition, I will make the instructor
          aware of any medical conditions or physical limitations before class.
          I knowingly and voluntarily assume all risk and responsibilities for
          any injury or accident that might occur to me during any classes or
          training activities with Yoga for Dance Academy. I hereby voluntarily
          release, forever discharge and agree to indemnify and hold harmless
          Roxie Nebel, Yoga for Dance Academy LLC their members, officers,
          directors, employees, agents, managers, operators, successors,
          assigns, heirs, beneficiaries, trustees, and attorneys from any and
          all claims, demands, or causes of action, which are in any way
          connected with my participation in the Yoga & Dance Classes, including
          any such classes that I have or may have that allege ordinary
          negligent acts or omissions by the indemnified parties referred
          herein. I have read and fully understand and agree to the above terms
          of this Agreement and Release of Waiver of Liability. I am signing
          this agreement voluntarily and recognize that by completing my
          purchase serves as complete and unconditional release of all liability
          to the greatest extent allowed by law in the state of North Carolina.
          Those under 18 years of age must have this form signed by a parent or
          guardian.
        </Typography>
      </Box>

      {/* Waiver Acceptance Checkbox */}
      <FormControlLabel
        control={
          <Checkbox
            checked={waiverAccepted}
            onChange={handleWaiverChange}
            sx={{
              padding: "4px",
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            }}
          />
        }
        label={
          <Typography
            sx={{ fontSize: "13px", color: "#555", marginLeft: "8px" }}
          >
            I have read and agree to the waiver
          </Typography>
        }
      />

      {/* Submit Button */}
      <Button
        type="submit"
        aria-label="Complete Purchase"
        variant="contained"
        fullWidth
        disabled={!canSubmit}
        sx={{
          marginTop: 2,
          padding: "12px",
          fontSize: "16px",
          backgroundColor: "#d88f96",
          color: "#fff",
          textTransform: "none",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#c47a85",
          },
        }}
      >
        {loading ? <CircularProgress size={24} /> : "Complete my purchase"}
      </Button>

      {/* Error Message Display */}
      {error && (
        <Typography color="error" sx={{ marginTop: "10px" }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

const Checkout = () => (
  <Elements stripe={stripePromise}>
    <Grid
      container
      spacing={4} // Increased spacing between the left and right sections
      sx={{
        padding: 4,
        justifyContent: "center",
      }}
    >
      {/* Left Section */}
      <Grid
        item
        xs={12}
        md={6}
        lg={5}
        sx={{
          maxWidth: { md: "600px", lg: "500px" },
          paddingRight: { md: 2 }, // Extra padding on the right side in desktop view
        }}
      >
        <Card
          elevation={2}
          sx={{
            marginBottom: 2,
            borderRadius: 1,
            boxShadow: "0 15px 20px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <CardMedia
            component="img"
            height="auto"
            image="/Flex_Checkout.webp"
            alt="Flexibility Masterclass"
            sx={{
              maxWidth: "100%",
              display: "block",
              verticalAlign: "middle",
              border: 0,
              boxSizing: "border-box",
            }}
          />
        </Card>

        <Typography
          variant="h4"
          component="h1"
          sx={{
            marginTop: "50px",
            marginBottom: "40px",
            lineHeight: 1.3,
            fontWeight: 600,
            color: "#0d0d0d",
            fontSize: "36px",
          }}
        >
          The Flexibility Masterclass
        </Typography>

        <Typography
          component="p"
          sx={{
            color: "#373f47",
            margin: "20px 0",
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
            fontSize: "14px",
            lineHeight: 1.71,
          }}
        >
          <strong>
            Learn Proven Formulas to Level up your FUNCTIONAL Flexibility
          </strong>
          <br />
          Go from Tight & Limited to Bendy & Fluid (Injury Free!) Make your
          flexibility work for you & start using your full mobility
          effortlessly.
        </Typography>

        <Typography
          component="p"
          sx={{ marginTop: "20px", marginBottom: "10px", fontWeight: "bold" }}
        >
          What you'll get:
        </Typography>

        <Box
          component="ul"
          sx={{
            color: "#373f47",
            paddingInlineStart: "40px",
            listStyleType: "disc",
          }}
        >
          <Typography component="li" sx={{ marginBottom: "10px" }}>
            <strong>2 Educational lectures</strong> exploring the science of
            flexibility and the most effective stretching techniques
          </Typography>
          <Typography component="li" sx={{ marginBottom: "10px" }}>
            <strong>3 full-length Yoga classes</strong> to embody & understand
            Active Mobility work for healthy joints & a happy nervous system
          </Typography>
          <Typography component="li" sx={{ marginBottom: "10px" }}>
            <strong>1 Mini-Training guide</strong> full of additional practical
            tips/resources for effectively increasing your flexibility, making
            your progress quicker AND safer
          </Typography>
        </Box>
      </Grid>

      {/* Right Section */}
      <Grid
        item
        xs={12}
        md={6}
        lg={5}
        sx={{
          maxWidth: { md: "450px", lg: "400px" },
          paddingLeft: { md: 2 },
        }}
      >
        <CheckoutForm />
      </Grid>
    </Grid>
  </Elements>
);

export default Checkout;
