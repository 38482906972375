import React from "react";

const AboutYDA = () => {
  return (
    <section
      className="relative py-16 lg:py-24"
      style={{
        backgroundColor: "#F4E0D7", // Soft pink background
        marginBottom: "0", // Ensure the component has no bottom margin
        paddingBottom: "0", // Ensure no extra padding at the bottom
      }}
    >
      <div
        className="container mx-auto px-2 lg:px-16" // Reduced padding on mobile, larger padding on larger screens
        style={{
          maxWidth: "calc(1260px + 40px + 40px)", // Applying the max-width
          marginBottom: "0", // Remove container's bottom margin
        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          {/* Left Side: Image */}
          <div
            className="relative"
            style={{
              marginTop: "-200px", // Moves the image up to overlap the component above
              marginBottom: "0", // Remove image container's bottom margin
            }}
          >
            <img
              src="/AboutYDA.webp"
              alt="Roxie Nebel"
              className="w-full"
              style={{
                display: "block",
                maxWidth: "100%",
                height: "auto",
                borderRadius: "0px",
                marginBottom: "0", // Ensure no extra space below the image
              }}
            />
          </div>

          {/* Right Side: Text */}
          <div
            className="relative bg-white shadow-none rounded-lg w-full lg:w-3/4 lg:max-w-lg p-4 lg:p-16 mx-auto"
            data-aos="fade"
            data-aos-delay="0"
            data-aos-duration="400"
            style={{
              border: "1px solid #e1a291", // Border color and radius
              borderRadius: "4px",
              backgroundColor: "#ffffff", // Background color
              boxSizing: "inherit",
              overflow: "hidden",
              marginBottom: "1.5rem", // Bottom margin
              paddingBottom: "1.5rem", // Bottom padding
            }}
          >
            {/* Updated H4 Styling */}
            <h4
              className="font-bird-and-thorn text-5xl lg:text-7xl" // Adjust font size for mobile
              style={{
                marginTop: "15px",
                textAlign: "center", // Center text
                fontWeight: "400", // Light weight font
                color: "#1d1d1d", // Header text color
                lineHeight: "1.2",
                whiteSpace: "nowrap", // Prevent line breaks on desktop
              }}
            >
              About Yoga
            </h4>

            {/* Styling for "FOR DANCERS" */}
            <p
              className="text-center"
              style={{
                color: "#9d646b", // Special color for "FOR DANCERS"
                letterSpacing: "0.2em", // Letter spacing
                fontSize: "21px", // Font size
                fontFamily: "Raleway, sans-serif", // Font family
                fontWeight: "400", // Font weight
                lineHeight: "1.6", // Line height for readability
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                marginBottom: "1rem", // Bottom margin
              }}
            >
              FOR DANCERS
            </p>

            {/* Paragraph Styling for Other Texts */}
            <p
              className="text-gray-600 mb-4"
              style={{
                marginTop: "0", // Consistent top margin
                marginBottom: "1rem", // Spacing below paragraph
                fontSize: "18px", // Font size
                lineHeight: "1.6", // Line height for readability
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                fontFamily: "Raleway", // Consistent font-family
                color: "#333333", // Text color
                textAlign: "center", // Text alignment
              }}
            >
              Join <strong>Roxie Nebel</strong> on a journey of self-care
              classes accessible and beneficial to dancers of all backgrounds,
              styles, levels, and ages!
            </p>

            <p
              className="text-gray-600"
              style={{
                marginTop: "0", // Consistent top margin
                marginBottom: "1rem", // Spacing below paragraph
                fontSize: "18px", // Font size
                lineHeight: "1.6", // Line height for readability
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
                fontFamily: "Raleway", // Consistent font-family
                color: "#333333", // Text color
                textAlign: "center", // Text alignment
              }}
            >
              What Roxie offers is a revolutionary and potent cross-training
              program combining her extensive real-world dance experience with
              the knowledge of yoga to keep dancers healthy and injury-free so
              they can get the most out of their dance career!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutYDA;
