import React from "react";
import Hero from "../components/About/Hero";
import About from "../components/About/About";
import Creds from "../components/About/Creds";
import Diff from "../components/About/Diff";
import InGoodHands from "../components/About/InGoodHands";
import Welcome from "../components/About/Welcome";
import Connect from "../components/Connect";
import Footer from "../components/Footer";

const AboutPage = () => {
  return (
    <div>
      <Hero />
      <About />
      <Creds />
      <Diff />
      <InGoodHands />
      <Welcome />
      <Connect />
      <Footer />
    </div>
  );
};

export default AboutPage;
