import React from "react";

const testimonials = [
  {
    text: "I've been doing the IT Band class 1-2 times a week and my knees and body feel AMAZING!!! That really was a game changer, I engaged my body in so many ways that I had taken for granted before.",
    name: "Justine",
    location: "Dancer in Flagstaff, AZ",
  },
  {
    text: "I loved her commentary throughout because it felt specific to me and not like I was in a big class where I didn’t matter. The connections to dance she made and the affirmations really helped! It’s amazing to get so much out of such small and slow movements, she was so helpful I feel ready for a nice sleep and a great day tomorrow.",
    name: "Janel",
    location: "Dancer, Ailey/Fordham BFA",
  },
  {
    text: "She has a great way of tailoring class to her students. Everything I felt I needed in my body was met in her class. She also has a lovely way of keeping things light-hearted, while also helping you dive into a relaxed state of mind.",
    name: "Tiana",
    location: "Dancer & Teacher in Salt Lake City",
  },
  {
    text: "My back is so strong and mobile, I've been doing the videos everyday, and honestly I feel the best I've felt in years!",
    name: "Maya",
    location: "Dancer in Flagstaff, AZ",
  },
  {
    text: "I'm so addicted! What powerful, inspirational videos. I haven't been able to get my hips feeling good and relaxed like the psoas video made them feel.",
    name: "Nadia",
    location: "Dancer in Tuscon AZ",
  },
  {
    text: "No joke your classes have helped my technique a ton!",
    name: "Julie",
    location: "Dancer in MN",
  },
];

const Testimonials = () => {
  return (
    <section
      className="section relative py-16 lg:py-24 bg-light-background"
      style={{
        backgroundImage: "url('/cards_background.jpg')", // Ensure the correct image path
        backgroundSize: "cover", // Ensures the image covers the full background
        backgroundPosition: "center", // Center the background image
        backgroundAttachment: "fixed", // Makes the background fixed while scrolling
      }}
    >
      <div className="container mx-auto px-4">
        {/* Main Heading */}
        <div className="text-center mb-8">
          <div className="inline-block">
            <h2
              className="font-notoserif text-3xl lg:text-5xl text-gray-800 font-light leading-tight mb-4 inline-block"
              style={{
                letterSpacing: "normal",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
              }}
            >
              Testimonials
            </h2>
            <hr
              className="mx-auto"
              style={{
                height: "2px",
                backgroundColor: "#E1A291",
                border: "none",
              }}
            />
          </div>
        </div>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="relative bg-white p-8 shadow-md rounded-lg overflow-hidden"
              data-aos="fade"
              data-aos-delay={`${index * 300}`}
              data-aos-duration="1000"
              style={{ boxShadow: "0 10px 40px 0 rgba(0, 0, 0, 0.1)" }} // Similar to reference
            >
              {/* Gold Quote Image */}
              <div className="flex justify-center mb-4">
                <img
                  src="/gold-quote.webp"
                  alt="Quote"
                  className="w-8"
                  style={{
                    marginBottom: "1rem",
                    display: "inline-block",
                  }}
                />
              </div>

              {/* Testimonial Text */}
              <p
                className="text-center text-gray-600 italic"
                style={{
                  fontSize: "18px", // Ensures text size consistency
                  marginBottom: "1rem", // Adds bottom margin
                  lineHeight: "1.6", // Adjusted line height for readability
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                <em>"{testimonial.text}"</em>
              </p>

              {/* Testimonial Name */}
              <h5
                className="text-center font-notoserif font-light text-gray-800"
                style={{
                  fontSize: "24px", // Font size for name
                  lineHeight: "1.2", // Adjusted line height
                  marginTop: "0", // No top margin
                  marginBottom: "0.5rem", // Spacing below the name
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                -{testimonial.name}
              </h5>

              {/* Testimonial Location */}
              <p
                className="text-center text-gray-500"
                style={{
                  fontSize: "16px", // Font size for locations
                  fontFamily: "Raleway", // Font family for location
                  fontWeight: "400", // Font weight for readability
                  lineHeight: "1.6", // Line height for proper spacing
                  marginTop: "0", // No margin on top
                  marginBottom: "0", // Removed margin-bottom to match design
                  WebkitFontSmoothing: "antialiased",
                  textRendering: "optimizeLegibility",
                }}
              >
                {testimonial.location}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
