import React from "react";
import "aos/dist/aos.css";

const About = () => {
  return (
    <section className="about-section bg-no-repeat bg-cover py-16 lg:py-28 min-h-screen lg:bg-[url('../public/Moon-and-circle.jpg')]">
      <div className="container px-4">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Image Section */}
          <div className="w-full sm:w-auto lg:w-1/2 mb-8 lg:mb-0 flex justify-center relative sm:-mt-12 lg:ml-[100px] lg:-mt-80">
            <img
              src="Roxie-bed.webp"
              alt="Roxie Nebel"
              className="rounded-lg w-[350px] sm:w-[400px] lg:w-[500px] object-cover"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            />
          </div>

          {/* Text Section */}
          <div className="lg:w-1/2 space-y-6 lg:max-w-[550px] lg:-ml-[50px] lg:-mt-12">
            <p className="text-left tracking-[.2em] text-[21px] font-raleway mt-0 mb-2">
              HEY, DANCERS!
            </p>
            <h3 className="text-3xl sm:text-4xl lg:text-4xl font-notoserif font-light leading-tight text-left">
              <span className="text-[#9d646b] italic relative">
                <span className="underline decoration-[#E1A291] decoration-[2px] underline-offset-[4px]">
                  I’m Roxie Nebel
                </span>
              </span>{" "}
              and I created The Yoga for Dance Academy.
            </h3>
            <p className="text-lg text-gray-700 mt-0">
              I’m an ex-ballerina turned professional pole dancer, yoga teacher,
              anatomy & alignment nerd, mother of serpents, Asheville livin'
              vegan food fanatic!
            </p>
            <p className="text-lg text-gray-700">
              But moreeee importantly.... I’m an advocate + resource for
              revolutionizing the outdated, harmful systems, mindsets & training
              regimens that ignore the well-being of dancers.
            </p>
          </div>
        </div>

        {/* Paragraph and H5 Below Image */}
        <div className="mt-10 lg:mt-16 lg:ml-[150px] lg:max-w-[600px] lg:pl-10 text-left">
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            With my system, it is possible to be at the top of your game without
            sacrificing your mental and physical health as a dancer.
          </p>

          {/* H5 Section */}
          <h5 className="text-[#1d1d1d] font-notoserif text-[20px] lg:text-[24px] font-thin leading-tight mt-0 mb-2">
            I’m here to offer{" "}
            <em>
              a <strong className="text-[#9d646b]">trauma-informed</strong> safe
              space
            </em>{" "}
            for dancers to heal, guidance to grow as confident artists & education
            to transform the way they train and relate to their body.
          </h5>
        </div>
      </div>
    </section>
  );
};

export default About;