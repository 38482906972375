import React from "react";

const Diff = () => {
  return (
    <section
      className="section relative pt-0 lg:pt-24"
      style={{
        paddingBottom: "0",
        backgroundImage: "url('/Diff_Background.jpg')",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      {/* Mobile Image Section */}
      <div
        className="block lg:hidden mb-8"
        data-aos="slide-right"
        data-aos-duration="1000" 
        data-aos-once="false"
      >
        <img
          src="/left-image.jpg"
          alt="Left Side of Background"
          className="w-full object-cover m-0"
          style={{
            borderRadius: "0",
            paddingTop: "0", // Ensures no padding at the top
            marginTop: "0", // Ensures no margin at the top
          }}
        />
      </div>

      {/* Mobile H4 Section */}
      <div className="block lg:hidden text-center mb-2">
        <h4
          className="text-h4-custom text-[#fafafa] font-bird-and-thorn"
          style={{
            fontSize: "50px !important", // Font size for mobile
            lineHeight: "1.2 !important",
            marginTop: "0 !important", // Remove top margin in mobile
            marginBottom: "0 !important", // Remove bottom margin in mobile
            paddingTop: "0 !important", // Remove padding top for mobile
            fontWeight: "400 !important",
            letterSpacing: "normal !important",
            WebkitFontSmoothing: "antialiased !important",
            textRendering: "optimizeLegibility !important",
          }}
        >
          Why I'm Different
        </h4>
      </div>

      <div className="absolute inset-0"></div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col lg:flex-row justify-end items-start">
          <div className="lg:w-2/3 p-6 lg:p-10 space-y-5 flex flex-col justify-center lg:items-center relative">
            {/* Desktop H4 Section */}
            <h4
              className="hidden lg:block absolute lg:top-[-25px] lg:left-1/2 text-h4-custom text-[#fafafa] font-bird-and-thorn"
              style={{
                transform: "translateX(-50%)",
                fontSize: "80px", // Font size for desktop
                lineHeight: "1.2",
                marginTop: "0",
                marginBottom: "2rem", // This will ensure spacing between h4 and blockquote in desktop
                color: "#fafafa",
                fontWeight: "400",
                letterSpacing: "normal",
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
              }}
            >
              Why I'm Different
            </h4>

            <blockquote
              className="w-full lg:w-[100%] mt-6 text-[#333] text-base leading-relaxed font-notoserif px-4 lg:px-10 max-w-[800px] mb-10 lg:mb-0"
              style={{
                paddingTop: "4rem", // Remove padding top for mobile
                paddingBottom: "2rem",
                marginTop: "0", // Keep mobile view with no margin
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "1.6",
                  fontWeight: "300", 
                  marginBottom: "1.5rem", 
                  color: "#333333",
                }}
              >
                My 16 years as a dancer gives me unparalleled insight into the
                real experiences & struggles dancers face. My programs are
                tailored to support your needs unlike any other regular yoga
                teacher.
              </p>
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "1.6",
                  fontWeight: "300", 
                  marginBottom: "1.5rem",
                  color: "#333333",
                }}
              >
                I teach you how to become your own health guru;{" "}
                <span className="text-[#9d646b] font-semibold">
                  you will be empowered & educated to continue thriving
                </span>{" "}
                even after you finish one of my programs or classes—cultivating
                your longevity & self-trust is one of my top priorities.
              </p>
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "1.6",
                  fontWeight: "300",
                  color: "#333333",
                }}
              >
                I care deeply for your inner fulfillment, purpose, health, &
                holistic transformation. I’ll give you{" "}
                <strong className="text-[#9d646b] font-semibold">
                  proven science & powerful techniques to reach physical
                  transformations
                </strong>{" "}
                every single week, as well as teach you{" "}
                <strong className="text-[#9d646b] font-semibold">
                  how to develop healthy mindset skills to overcome mental
                  blocks holding you back
                </strong>{" "}
                from your most confident self!
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Diff;
